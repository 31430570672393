<!-- @format -->

<template>
  <mq-layout mq="lg+">
    <header id="header" class="header" :class="$mq">
      <div class="header__content">
        <nav id="navi">
          <BaseNavigation />
        </nav>
        <nav v-if="kinofinder" id="finder">
          <BaseKinofinder v-if="$config.kinoservice.display" />
        </nav>
      </div>
      <component
        :is="companySocial"
        :facebook="true"
        :youtube="false"
        :instagram="false"
        v-if="social"
        class="header__social"
      ></component>
    </header>
  </mq-layout>
</template>

<script>
  export default {
    props: {
      social: {
        type: Boolean,
        default: true,
      },
      kinofinder: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      companySocial() {
        return () =>
          import(
            `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Social.vue`
          )
      },
    },
  }
</script>

<style lang="scss">
  .header {
    $self: &;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $header-height;
    background-color: $header-bg;
    z-index: 599;
    overflow: hidden;
    &__content {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: row;
      padding: $header-padding;
      height: $header-height;
    }
    &__social {
      position: absolute;
      top: 0;
      right: 20px;
      text-align: right;
    }
  }
</style>
