<!-- @format -->
<template>
  <mq-layout :mq="['sm', 'md']">
    <div class="mobile-top" id="index">
      <img src="@/assets/images/bg_top_mobile.jpg" alt="" />
    </div>
  </mq-layout>
</template>

<script>
  import { EventBus } from '@/plugins/event-bus.js'

  export default {
    props: {
      kinostart1: {
        type: String,
        default: 'Releaseline',
      },
      kinostart2: {
        type: String,
        default: 'Releaseline',
      },
      showKinofinder: {
        type: Boolean,
        default: true,
      },
    },
  }
</script>

<style lang="scss">
  .no-scroll {
    overflow: hidden;
    height: 100vh;
    scrollbar-width: 0;
  }
  .fullScreen {
    height: 100vh;
  }
  .mobile-top {
    $self: &;
    position: relative;
    margin-top: 50px;
    &__kinostart {
      font-weight: 700;
      text-transform: uppercase;
      width: 100%;
      padding: 1rem;
      &.md,
      &.lg,
      &.xl,
      &.xxl {
        padding: 1rem 4rem;
      }
    }
  }
  .flickity-viewport {
    .flickity-slider {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
</style>
