<!-- @format -->

<template>
  <div class="regie" id="regie">
    <div class="regie__header" :class="$mq">
      <div class="regie__pic" :class="$mq">
        <img src="@/assets/images/regie/r1.jpg" alt="" />
      </div>
      <div class="regie__headline" :class="$mq">
        <h1 v-html="$texte.regie.h1"></h1>
        <h2 v-html="$texte.regie.subline"></h2>
        <div class="regie__teaser" v-html="$texte.regie.teaser"></div>
      </div>
    </div>
    <div class="regie__text" :class="$mq" id="interview">
      <div style="margin-bottom: 20px">
        <h1>FRAGEN AN EVELYN SCHELS</h1>
        <h2>INTERVIEW</h2>
      </div>
      <div v-if="!open">
        <div class="c-2" v-html="$texte.regie.text"></div>
        <div v-if="!open" class="regie__btn btn-pos" @click="open = true">
          <PlusIcon /> Weiterlesen
        </div>
      </div>
      <div v-if="open">
        <div class="c-2" v-html="$texte.regie.more"></div>
        <div v-if="open" class="regie__btn btn-pos" @click="open = false">
          <MinusIcon /> Schliessen
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PlusIcon from 'vue-material-design-icons/PlusCircle.vue'
  import MinusIcon from 'vue-material-design-icons/MinusCircle.vue'

  export default {
    components: {
      PlusIcon,
      MinusIcon,
    },
    data() {
      return {
        open: false,
      }
    },
  }
</script>

<style lang="scss">
  .regie {
    --width: 1400px;
    $self: &;
    position: relative;
    text-align: left;
    background-color: white;
    h1 {
      text-transform: uppercase;
      font-size: 42px;
      line-height: 45px;
      font-weight: 900;
      color: $h1;
      span {
        color: $tertiary;
      }
    }
    h2 {
      font-size: 32px;
      font-weight: 700;
      color: $tertiary;
      span {
        display: block;
        font-size: 17px;
        line-height: 17px;
        color: #000;
      }
    }
    &__header {
      display: flex;
      flex-direction: row;
      border-bottom: 2px solid $tertiary;
      width: var(--width);
      margin: 0 auto;
      padding: 80px 0 40px 0;
      &.lg {
        width: 960px;
        flex-direction: column;
      }
      &.xl {
        width: 1200px;
      }
      #{$self}__pic {
        &.lg {
          margin-bottom: 20px;
        }
      }
      #{$self}__headline {
        width: 50%;
        margin-left: 50px;
        &.lg {
          width: 100%;
          margin-left: 0;
        }
        #{$self}__teaser {
          margin-top: 15px;
        }
      }
    }
    &__text {
      text-align: left;
      margin: 0 auto;
      padding: 40px 0 80px 0;
      width: var(--width);
      &.lg {
        width: 960px;
      }
      &.xl {
        width: 1200px;
      }
      span {
        font-weight: 900;
      }
    }
    &__btn {
      color: $tertiary;
      cursor: pointer;
      display: inline-block;
      position: relative;
      padding: 15px 0 15px 15px;
      margin-left: 50%;
    }
  }
</style>
