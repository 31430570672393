<!-- @format -->

<template>
  <div class="inhalt" id="inhalt">
    <BaseCitesFade
      class="inhalt__cites"
      v-scroll-reveal.reset="{
        duration: 1000,
        delay: 150,
        origin: 'top',
        distance: '20px',
        scale: 0,
      }"
    />
    <div
      class="inhalt__wrapper"
      v-scroll-reveal.reset="{
        duration: 1000,
        delay: 250,
        origin: 'bottom',
        distance: '40px',
      }"
    >
      <div
        class="inhalt__text c-2"
        v-html="$texte.inhalt.text"
        :class="$mq"
      ></div>
    </div>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss">
  .inhalt {
    $self: &;
    position: relative;
    border-top: 20px solid white;
    padding-bottom: 40px;
    background-color: #fff;
    &__cites {
      margin-top: 47px;
    }
    &__wrapper {
      #{$self}__text {
        margin: 50px auto;
        width: 1400px;
        &.lg {
          width: 960px;
        }
        &.xl {
          width: 1200px;
        }
        span {
          font-weight: 900;
        }
      }
    }
  }
</style>
