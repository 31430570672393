<!-- @format -->

<template>
  <div id="index" style="pointer-events: none; position: relative">
    <div class="desktop-top">
      <div class="desktop-top__bg"></div>
      <div class="packshot">
        <a :href="this.$config.dvd_link" target="_blank">
          <img src="~@/assets/images/packshot.png" alt="Hier kaufen *" />
        </a>
      </div>
      <div class="desktop-top__wrapper">
        <div class="desktop-top__images">
          <div class="desktop-top__images-content">
            <div
              v-for="(artist, index) in $texte.desktopArtists"
              :key="artist.name"
              @mouseenter="mouseEnter"
              @mouseleave="mouseLeave"
              class="desktop-top__image-wrapper"
              :id="`scene${index + 1}`"
              v-scroll-to="{
                el: '#a' + (index + 1),
                offset: -70,
              }"
            >
              <div
                :data-depth="artist.depth"
                class="desktop-top__image layer"
                :class="`${$mq} desktop-top__image-${index + 1}`"
                :style="[
                  ratio < 2.5
                    ? {
                        backgroundSize: 'auto 100%',
                      }
                    : {
                        backgroundSize: 'auto',
                      },
                ]"
              ></div>
              <div class="desktop-top__image-overlay-full"></div>
              <div class="desktop-top__image-overlay">
                <div
                  class="desktop-top__image-overlay-text"
                  v-html="artist.name"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="desktop-top__content" :class="$mq">
          <div class="desktop-top__tt" :class="$mq">
            <img :src="require(`@/assets/images/` + $config.tt)" alt="" />
          </div>
          <div class="desktop-top__von" :class="$mq">
            <img src="@/assets/images/von.png" alt="" />
          </div>
          <div class="desktop-top__trailer" :class="$mq">
            <div v-if="$config.youtube.display">
              <div
                @click="ytVideo"
                class="open-trailer"
                style="position: relative"
              >
                <video
                  src="@/assets/videos/sizzle.mp4"
                  loop
                  muted
                  autoplay
                ></video>
                <div class="trailer-misc">
                  <img
                    :style="[
                      ratio < 1.73
                        ? {
                            width: '40px',
                          }
                        : {
                            width: '70px',
                          },
                    ]"
                    class="play-btn"
                    src="@/assets/images/trailer/play.png"
                    alt=""
                  />
                  <div class="trailer-text">Trailer ansehen</div>
                </div>
              </div>
            </div>
          </div>
          <BaseKinostart :kinostart1="kinostart1" :kinostart2="kinostart2" />
          <div class="btn_kaufen">
            <a :href="this.$config.dvd_link" target="_blank">
              <img src="@/assets/images/btn_kaufen.png" alt="Hier kaufen *" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { EventBus } from '@/plugins/event-bus.js'
  import Parallax from 'parallax-js'

  export default {
    props: {
      kinostart1: {
        type: String,
        default: 'Releaseline',
      },
      kinostart2: {
        type: String,
        default: 'Releaseline',
      },
      ratio: {
        type: Number,
        default: 1.78,
      },
      trailer: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {
      this.$nextTick(function () {
        var scene1 = document.getElementById('scene1')
        /* eslint-disable-next-line no-new */
        new Parallax(scene1, { limitY: 0, scalarX: 4, selector: '.layer' })
        var scene2 = document.getElementById('scene2')
        /* eslint-disable-next-line no-new */
        new Parallax(scene2, { limitY: 0, scalarX: 4, selector: '.layer' })
        var scene3 = document.getElementById('scene3')
        /* eslint-disable-next-line no-new */
        new Parallax(scene3, { limitY: 0, scalarX: 4, selector: '.layer' })
        var scene4 = document.getElementById('scene4')
        /* eslint-disable-next-line no-new */
        new Parallax(scene4, { limitY: 0, scalarX: 4, selector: '.layer' })
      })
    },
    methods: {
      mouseEnter(e) {
        e.target.children[2].classList.add('db')
      },
      mouseLeave(e) {
        e.target.children[2].classList.remove('db')
      },
      ytVideo() {
        EventBus.$emit('open-yt')
      },
    },
  }
</script>

<style lang="scss">
  .db {
    visibility: visible !important;
    animation-name: vis;
    animation-duration: 0s;
    animation-fill-mode: forwards;
  }
  @keyframes display {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes vis {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }
  .desktop-top {
    $self: &;
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .btn_kaufen {
      pointer-events: all;
      cursor: pointer;
      margin-top: 1rem;
      width: 12vw;
      a[href='#'] {
        filter: grayscale(100%);
        opacity: 0.8;
        cursor: text !important;
        pointer-events: none;
      }
      img {
        width: 100%;
        max-width: 257px;
      }
    }
    .packshot {
      pointer-events: all;
      position: absolute;
      z-index: 1;
      cursor: pointer;
      left: 50%;
      transform: translateX(-52%);
      bottom: 1.5%;
      width: 8vw;
      padding: 0;
      a[href='#'] {
        filter: grayscale(100%);
        opacity: 0.8;
        cursor: text !important;
        pointer-events: none;
      }
      img {
        width: 100%;
        max-width: 216px;
      }
    }
    &__bg {
      position: fixed;
      overflow: hidden;
      width: 100%;
      height: 100%;
      background-color: $primary;
    }
    &__wrapper {
      position: relative;
      padding-top: $header-height;
      height: 100%;
      display: flex;
      flex-direction: row;
      #{$self}__images {
        width: 50%;
        padding: 44px 0 44px 44px;
        #{$self}__images-content {
          position: relative;
          overflow: hidden;
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          #{$self}__image-wrapper {
            position: relative;
            width: 25%;
            height: 100%;
            overflow: hidden;
            pointer-events: auto !important;
            cursor: pointer;
            #{$self}__image-overlay-full {
              pointer-events: none;
              display: block;
              position: absolute;
              left: 0;
              top: 0;
              width: 120%;
              height: 100%;
              background: linear-gradient(
                180deg,
                rgba(74, 20, 49, 1) 0%,
                rgba(74, 20, 49, 0.09287464985994398) 20%,
                rgba(74, 20, 49, 0.08727240896358546) 80%,
                rgba(74, 20, 49, 1) 100%
              );
              opacity: 1;
            }
            #{$self}__image-overlay {
              visibility: hidden;
              position: absolute;
              bottom: 30% !important;
              top: unset !important;
              left: 0;
              width: 120%;
              background-color: $primary;
              opacity: 0.8;
              #{$self}__image-overlay-text {
                color: white;
                text-transform: uppercase;
                margin-right: 15%;
                text-align: center;
                line-height: 1;
                font-weight: 300;
                padding: 10px 0;
                $min_width: 1024px;
                $max_width: 2560px;
                $min_font: 17px;
                $max_font: 40px;
                @include fluid-type(
                  $min_width,
                  $max_width,
                  $min_font,
                  $max_font
                );
                span {
                  display: block;
                  font-weight: 900;
                }
              }
            }
          }
          #{$self}__image {
            margin-left: -10% !important;
          }
          #{$self}__image-1 {
            height: 110%;
            width: 200%;
            background: transparent url($assets + '/images/top/1.jpg') center
              left no-repeat;
            background-size: auto 100%;
            background-position-x: 45%;
          }
          #{$self}__image-2 {
            height: 100%;
            width: 200%;
            background: transparent url($assets + '/images/top/2.jpg') center
              left no-repeat;
            background-size: cover auto;
            background-position-x: 50%;
            &.lg {
            }
          }
          #{$self}__image-3 {
            height: 100%;
            width: 200%;
            background: transparent url($assets + '/images/top/3.jpg') center
              left no-repeat;
            background-size: cover auto;
            background-position-x: 55%;
            &.lg {
            }
          }
          #{$self}__image-4 {
            height: 100%;
            width: 200%;
            background: transparent url($assets + '/images/top/4.jpg') center
              left no-repeat;
            background-size: cover auto;
            background-position-x: 60%;
          }
        }
      }
      #{$self}__content {
        position: relative;
        width: 50%;
        height: 100%;
        padding: 2% 5% 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        #{$self}__tt {
          width: 100%;
        }
        #{$self}__von {
          margin-top: 3%;
        }
        #{$self}__trailer {
          pointer-events: auto;
          margin-top: 3%;
          position: relative;
          width: 60%;
          margin-bottom: 1.5rem;
          border: 10px solid white;
          &.lg {
            margin-top: 1.5rem;
            width: 80%;
          }
          cursor: pointer;
          .trailer-misc {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 111;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .trailer-text {
              font-weight: 400;
              color: white;
              font-size: 16px;
              text-transform: uppercase;
              margin-top: 0.5rem;
            }
            .trailer-quelle {
              position: absolute;
              right: 5px;
              bottom: 5px;
              font-size: 12px;
              color: white;
              line-height: 1;
            }
          }
        }
      }
    }
  }
</style>
