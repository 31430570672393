var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inhalt",attrs:{"id":"inhalt"}},[_c('BaseCitesFade',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:({
      duration: 1000,
      delay: 150,
      origin: 'top',
      distance: '20px',
      scale: 0,
    }),expression:"{\n      duration: 1000,\n      delay: 150,\n      origin: 'top',\n      distance: '20px',\n      scale: 0,\n    }",modifiers:{"reset":true}}],staticClass:"inhalt__cites"}),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:({
      duration: 1000,
      delay: 250,
      origin: 'bottom',
      distance: '40px',
    }),expression:"{\n      duration: 1000,\n      delay: 250,\n      origin: 'bottom',\n      distance: '40px',\n    }",modifiers:{"reset":true}}],staticClass:"inhalt__wrapper"},[_c('div',{staticClass:"inhalt__text c-2",class:_vm.$mq,domProps:{"innerHTML":_vm._s(_vm.$texte.inhalt.text)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }