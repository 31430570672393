<!-- @format -->

<template>
  <div id="artists" class="artists">
    <h1>Die Künstlerinnen</h1>
    <div
      v-for="(artist, index) in $texte.artists"
      :key="artist.text"
      class="artists__wrapper"
      :class="$mq"
      :id="`a${index}`"
    >
      <div class="artists__inner" :class="$mq" :style="switchSide(index)">
        <div class="artists__inner-image">
          <img
            :src="require(`@/assets/images/kuenstler/${artist.image}`)"
            alt=""
          />
        </div>
        <div class="artists__content" :style="switchMargin(index)">
          <h2 v-html="artist.name"></h2>
          <div v-if="open !== index" v-html="artist.teaser"></div>
          <div
            v-if="open !== index"
            class="artists__btn"
            @click="openArtist(index)"
          >
            <PlusIcon /> Weiterlesen
          </div>
          <div v-if="open === index" v-html="artist.more"></div>
          <div v-if="open === index" class="artists__btn" @click="closeArtist">
            <MinusIcon /> Schliessen
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PlusIcon from 'vue-material-design-icons/PlusCircle.vue'
  import MinusIcon from 'vue-material-design-icons/MinusCircle.vue'

  export default {
    components: {
      PlusIcon,
      MinusIcon,
    },
    data() {
      return {
        open: -1,
      }
    },
    computed: {},
    methods: {
      openArtist(index) {
        this.open = index
      },
      closeArtist() {
        this.open = -1
      },
      switchSide(index) {
        return index % 2 && `flex-direction: row-reverse;`
      },
      switchMargin(index) {
        return index % 2 && `margin-left:0; margin-right: 50px;`
      },
    },
  }
</script>

<style lang="scss">
  .artists {
    position: relative;
    --width: 1400px;
    background-color: black;
    $self: &;
    h1 {
      color: white;
      text-transform: uppercase;
      font-size: 55px;
      text-align: center;
      padding: 70px 0;
    }
    &__wrapper {
      margin: 0 auto;
      width: var(--width);
      color: white;
      padding-bottom: 60px;
      &.lg {
        width: 960px;
      }
      &.xl {
        width: 1200px;
      }
      h2 {
        color: $tertiary;
        font-weight: 400;
        margin-bottom: 15px;
        font-size: 35px;
      }
      #{$self}__btn {
        color: $tertiary;
        cursor: pointer;
        display: inline-block;
        position: relative;
        padding: 15px 0;
      }
      #{$self}__inner {
        display: flex;
        flex-direction: row;
        &.lg {
          flex-direction: column !important;
          #{$self}__inner-image {
            margin-bottom: 20px;
          }
          #{$self}__content {
            width: 100%;
            margin-left: 0;
          }
        }
        #{$self}__inner-image {
          image {
            width: 100%;
            height: auto;
          }
        }
        #{$self}__content {
          width: 50%;
          margin-left: 50px;
        }
      }
    }
  }
</style>
