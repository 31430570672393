<!-- @format -->

<template>
  <footer class="footer">
    <div class="footer__content">
      <div class="footer__tt" :class="$mq">
        <img :src="require(`@/assets/images/` + $config.tt_small)" alt="" />
      </div>
      <BaseKinostart :kinostart1="kinostart1" :kinostart2="kinostart2" />
      <!-- SECTION Footer Company -> Chooses Footer by $config.verantwortlich -> look @ computed -->
      <component
        class="footer__company"
        :is="companyFooter"
        :teilnahmebedingung="teilnahmebedingung"
      ></component>
      <component class="footer__social" :is="companySocial"></component>
    </div>
    <p class="startext">
      * Der mit Sternchen (*) gekennzeichnete Link ist ein sogenannter
      Affiliate-Link. Wenn Sie auf einen Affiliate-Link klicken und über diesen
      Link einkaufen, <br />
      erhält Filmwelt Verleihagentur GmbH vom betreffenden Online-Shop oder
      Anbieter eine Provision. Für Sie verändert sich der Preis nicht.
    </p>
    <div class="footer__billing" :class="$mq">
      <img src="@/assets/images/billing.png" alt="" />
    </div>
    <div class="cb-restore"></div>
  </footer>
</template>

<script>
  export default {
    props: {
      billing: {
        type: Boolean,
        default: true,
      },
      youtubenote: {
        type: Boolean,
        default: true,
      },
      kinostart1: {
        type: String,
        default: 'Releaseline',
      },
      kinostart2: {
        type: String,
        default: 'Releaseline',
      },
      teilnahmebedingung: {
        type: String,
        default: 'leer',
      },
    },
    computed: {
      companyFooter() {
        return () =>
          import(
            `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Footer.vue`
          )
      },
      companySocial() {
        return () =>
          import(
            `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Social.vue`
          )
      },
    },
  }
</script>

<style lang="scss">
  .footer {
    $self: &;
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 0.875rem;
    background-color: $primary;
    padding-bottom: 8rem;
    &__content {
      position: relative;
      margin: 0 auto;
      padding-top: 2rem;
      #{$self}__tt {
        margin: 40px auto;
        &.sm,
        &.md {
          padding: 0 5%;
          margin: 40px auto 0 auto;
        }
      }
      .base-kinostart {
        color: white;
        font-weight: 900;
        text-transform: uppercase;
        padding: 10px 0;
        &.lg,
        &.xl,
        &.xxl {
          $min_width: 1024px;
          $max_width: 2560px;
          $min_font: 35px;
          $max_font: 45px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
        }
        &.sm,
        &.md {
          $min_width: 300px;
          $max_width: 1023px;
          $min_font: 22px;
          $max_font: 45px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
        }
      }
    }

    .startext {
      color: white;
      font-size: clamp(8px, 0.9vw, 11px);
    }
    #{$self}__billing {
      /*margin-top: 50px;*/
      &.sm,
      &.md {
        margin-top: 0;
      }
      &.sm,
      &.md,
      &.lg,
      &.xl {
        padding: 0 5%;
      }
    }
  }
</style>
