var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"pointer-events":"none","position":"relative"},attrs:{"id":"index"}},[_c('div',{staticClass:"desktop-top"},[_c('div',{staticClass:"desktop-top__bg"}),_c('div',{staticClass:"packshot"},[_c('a',{attrs:{"href":this.$config.dvd_link,"target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/packshot.png"),"alt":"Hier kaufen *"}})])]),_c('div',{staticClass:"desktop-top__wrapper"},[_c('div',{staticClass:"desktop-top__images"},[_c('div',{staticClass:"desktop-top__images-content"},_vm._l((_vm.$texte.desktopArtists),function(artist,index){return _c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: '#a' + (index + 1),
              offset: -70,
            }),expression:"{\n              el: '#a' + (index + 1),\n              offset: -70,\n            }"}],key:artist.name,staticClass:"desktop-top__image-wrapper",attrs:{"id":("scene" + (index + 1))},on:{"mouseenter":_vm.mouseEnter,"mouseleave":_vm.mouseLeave}},[_c('div',{staticClass:"desktop-top__image layer",class:(_vm.$mq + " desktop-top__image-" + (index + 1)),style:([
                _vm.ratio < 2.5
                  ? {
                      backgroundSize: 'auto 100%',
                    }
                  : {
                      backgroundSize: 'auto',
                    } ]),attrs:{"data-depth":artist.depth}}),_c('div',{staticClass:"desktop-top__image-overlay-full"}),_c('div',{staticClass:"desktop-top__image-overlay"},[_c('div',{staticClass:"desktop-top__image-overlay-text",domProps:{"innerHTML":_vm._s(artist.name)}})])])}),0)]),_c('div',{staticClass:"desktop-top__content",class:_vm.$mq},[_c('div',{staticClass:"desktop-top__tt",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/" + _vm.$config.tt),"alt":""}})]),_c('div',{staticClass:"desktop-top__von",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/von.png"),"alt":""}})]),_c('div',{staticClass:"desktop-top__trailer",class:_vm.$mq},[(_vm.$config.youtube.display)?_c('div',[_c('div',{staticClass:"open-trailer",staticStyle:{"position":"relative"},on:{"click":_vm.ytVideo}},[_c('video',{attrs:{"src":require("@/assets/videos/sizzle.mp4"),"loop":"","muted":"","autoplay":""},domProps:{"muted":true}}),_c('div',{staticClass:"trailer-misc"},[_c('img',{staticClass:"play-btn",style:([
                    _vm.ratio < 1.73
                      ? {
                          width: '40px',
                        }
                      : {
                          width: '70px',
                        } ]),attrs:{"src":require("@/assets/images/trailer/play.png"),"alt":""}}),_c('div',{staticClass:"trailer-text"},[_vm._v("Trailer ansehen")])])])]):_vm._e()]),_c('BaseKinostart',{attrs:{"kinostart1":_vm.kinostart1,"kinostart2":_vm.kinostart2}}),_c('div',{staticClass:"btn_kaufen"},[_c('a',{attrs:{"href":this.$config.dvd_link,"target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/btn_kaufen.png"),"alt":"Hier kaufen *"}})])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }